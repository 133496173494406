import { Text } from "@chakra-ui/react";
import { Pass } from "../../../types/passes";
import { UserPass } from "../../../types/user_passes";
import { format } from "date-fns";

interface PassAboutTextProps {
  pass: Pass;
  userPass?: UserPass;
}

export const PassAboutText: React.FC<PassAboutTextProps> = ({
  pass,
  userPass,
}) => {
  return (
    <>
      {userPass?.is_valid ? (
        <>
          {pass.is_unlimited ? (
            <Text size="sm">Unlimited access</Text>
          ) : (
            <Text size="sm">{userPass.num_credits} remaining</Text>
          )}

          {pass.is_subscription && userPass.expires_at ? (
            <Text size="sm">
              Will renew around {format(userPass.expires_at, "EEE, MMM dd")}
            </Text>
          ) : (
            userPass.expires_at && (
              <Text size="sm">
                Expires on {format(userPass.expires_at, "EEE, MMM dd")}
              </Text>
            )
          )}
        </>
      ) : (
        <>
          {pass.is_unlimited ? (
            <Text size="sm">Unlimited access</Text>
          ) : (
            <Text size="sm">Valid for {pass.num_credits ?? 0} events</Text>
          )}

          {pass.is_subscription ? (
            <Text size="sm">
              Renews every {pass.num_months_active}{" "}
              {pass.num_months_active === 1 ? "month" : "months"}
            </Text>
          ) : (
            pass.num_months_active && (
              <Text size="sm">
                Valid for {pass.num_months_active}{" "}
                {pass.num_months_active === 1 ? "month" : "months"}
              </Text>
            )
          )}
        </>
      )}
    </>
  );
};
