import { useNavigate } from "react-router-dom";
import { useCurrentOrganization } from "../../providers/CurrentOrganizationProvider";
import { createPass } from "../../services/api/passes";
import { Pass } from "../../types/passes";
import { PassForm } from "./components/PassForm";
import { organizationPassesUrl } from "../../services/routes/urlBuilder";
import { Heading } from "@chakra-ui/react";

export const NewPass: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const navigate = useNavigate();

  const handleSubmit = async (data: Pass) => {
    if (!organization) return;

    await createPass(organization.id, data);
    navigate(organizationPassesUrl(organization.id));
  };

  return (
    <>
      <Heading size="lg">Add a pass</Heading>
      <PassForm onSubmit={handleSubmit} />
    </>
  );
};
