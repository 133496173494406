import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { VianikoEvent } from "../../../../types/events";
import { Pass } from "../../../../types/passes";
import { useEffect, useState } from "react";
import { updateEventPasses } from "../../../../services/api/events";
import { updateRecurringEventPasses } from "../../../../services/api/recurringEvents";

interface EditEventPassesModalProps {
  event: VianikoEvent;
  passes: Pass[];
  isOpen: boolean;
  onClose: () => void;
}

export const EditEventPassesModal: React.FC<EditEventPassesModalProps> = ({
  event,
  passes,
  isOpen,
  onClose,
}) => {
  const [passIds, setPassIds] = useState<string[]>([]);

  useEffect(() => {
    if (!event?.passes) return;

    setPassIds(event.passes.map((pass) => pass.id));
  }, [event.passes]);

  const handleChangePass =
    (pass: Pass) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setPassIds([...passIds, pass.id]);
      } else {
        setPassIds(passIds.filter((id) => id !== pass.id));
      }
    };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (event.recurring_event_id) {
      await updateRecurringEventPasses(event.id, passIds);
    } else {
      await updateEventPasses(event.id, passIds);
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>Change passes</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems="stretch" gap={4}>
            <Text size="md">
              Passes available for this event
              {!!event.recurring_event_id ? " series" : ""}
            </Text>

            <form onSubmit={onSubmit}>
              <CheckboxGroup value={passIds}>
                {passes.map((pass) => (
                  <FormControl key={pass.id} display="flex" gap={2}>
                    <Checkbox
                      value={pass.id}
                      onChange={handleChangePass(pass)}
                      isChecked={passIds.includes(pass.id)}
                    />
                    <FormLabel>{pass.name}</FormLabel>
                  </FormControl>
                ))}
              </CheckboxGroup>

              <Button
                type="submit"
                variant="primary"
                size="lg"
                width="100%"
                marginY={4}
              >
                Save
              </Button>
            </form>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
