import {
  CheckboxProps,
  Flex,
  Heading,
  HStack,
  useCheckbox,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RRule } from "rrule";

interface DaysOfWeekCheckboxProps {
  onChange: (days: number[]) => void;
  defaultValue?: number[];
}

const daysOfWeek: { value: number; label: string }[] = [
  { value: RRule.MO.weekday, label: "Mo" },
  { value: RRule.TU.weekday, label: "Tu" },
  { value: RRule.WE.weekday, label: "We" },
  { value: RRule.TH.weekday, label: "Th" },
  { value: RRule.FR.weekday, label: "Fr" },
  { value: RRule.SA.weekday, label: "Sa" },
  { value: RRule.SU.weekday, label: "Su" },
];

export const DaysOfWeekCheckbox: React.FC<DaysOfWeekCheckboxProps> = ({
  defaultValue,
  onChange,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  useEffect(() => {
    if (defaultValue?.length && !initialized) {
      setSelectedDays(defaultValue);
      setInitialized(true);
    }
  }, [defaultValue, initialized]);

  const toggleDay = (day: number) => {
    let nextDays;
    if (selectedDays.includes(day)) {
      nextDays = selectedDays.filter((d) => d !== day);
    } else {
      nextDays = [...selectedDays, day];
    }
    setSelectedDays(nextDays);
    onChange(nextDays);
  };

  return (
    <HStack gap={1}>
      {daysOfWeek.map((day, index) => (
        <DayOfWeekCheckbox
          isChecked={selectedDays.includes(day.value)}
          onChange={() => toggleDay(day.value)}
          key={index}
        >
          <Heading size="sm">{day.label}</Heading>
        </DayOfWeekCheckbox>
      ))}
    </HStack>
  );
};

const DayOfWeekCheckbox: React.FC<CheckboxProps> = (checkboxProps) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(checkboxProps);

  return (
    <label {...getLabelProps()} {...htmlProps}>
      <input {...getInputProps()} />
      <Flex
        {...getCheckboxProps()}
        width="46px"
        height="32px"
        borderRadius="24px"
        align="center"
        justify="center"
        cursor="pointer"
        bg={state.isChecked ? "black" : "white"}
        borderColor={state.isChecked ? "none" : "brand.500"}
        border="2px solid"
        color={state.isChecked ? "white" : "black"}
      >
        {checkboxProps.children}
      </Flex>
    </label>
  );
};
