import { Box, Heading, VStack } from "@chakra-ui/react";
import { useFeaturedOrganizations } from "../../hooks/useFeaturedOrganizations";
import { OrganizationListThemeCard } from "./components/OrganizationListThemeCard";

export const FeaturedOrganizations = () => {
  const { organizations } = useFeaturedOrganizations();

  return organizations.length > 0 ? (
    <Box marginY={16}>
      <VStack gap={1} maxW="2xl" marginX="auto">
        <Heading size="xl" as="h3" margin={4} alignSelf="start">
          Featured organizations
        </Heading>
        {organizations.map((organization) => (
          <OrganizationListThemeCard
            key={organization.id}
            organization={organization}
          />
        ))}
      </VStack>
    </Box>
  ) : null;
};
