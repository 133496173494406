import { useCallback, useEffect, useState } from "react";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { fetchRecurringEvents } from "../services/api/recurringEvents";
import { RecurringEvent } from "../types/recurring_events";

interface UserRecurringEvents {
  recurringEvents: RecurringEvent[] | undefined;
}

export const useRecurringEvents = (
  organizationId: string | undefined
): UserRecurringEvents => {
  const [recurringEvents, setRecurringEvents] = useState<
    RecurringEvent[] | undefined
  >();
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!currentUser || !organizationId) return;

    const result = await fetchRecurringEvents(organizationId);
    setRecurringEvents(result || []);
  }, [currentUser, organizationId]);

  useEffect(() => {
    if (!currentUser) return;
    fetch();
  }, [currentUser, fetch]);

  return { recurringEvents };
};
