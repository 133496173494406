import { Box, Heading, HStack, Spacer, Text } from "@chakra-ui/react";
import { priceInDollars } from "../../../services/payments";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { Pass } from "../../../types/passes";
import { ManagePassInfoMenu } from "../../passes/components/ManagePassInfoMenu";
import { PassAboutText } from "./PassAboutText";

interface AdminPassInfoProps {
  pass: Pass;
  onDelete: () => void;
}

export const AdminPassInfo: React.FC<AdminPassInfoProps> = ({
  pass,
  onDelete,
}) => {
  const handleDelete = () => {
    onDelete();
  };

  return (
    <>
      <HStack align="start">
        <Box>
          <Heading as="h3" size="md">
            {pass.name}
          </Heading>

          <PassAboutText pass={pass} />

          {pass.description && (
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              {pass.description}
            </Text>
          )}
        </Box>

        <Spacer />

        <HStack>
          <Text size="md" color={TEXT_SECONDARY_COLOR}>
            {priceInDollars("USD", pass.price_in_cents)}
          </Text>

          <ManagePassInfoMenu pass={pass} onDelete={handleDelete} />
        </HStack>
      </HStack>
    </>
  );
};
