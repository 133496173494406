import { Heading, Text } from "@chakra-ui/react";
import { priceInDollars } from "../../services/payments";
import { CURRENCY_CODE_TO_CURRENCY } from "../../services/currencies";
import { Elements } from "@stripe/react-stripe-js";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { useStripeAccount } from "../../hooks/useStripeAccount";
import { PassPaymentForm } from "./PassPaymentForm";
import { Pass } from "../../types/passes";

interface PassSubscriptionPaymentProps {
  pass: Pass;
}

export const PassSubscriptionPayment: React.FC<
  PassSubscriptionPaymentProps
> = ({ pass }) => {
  const { organization } = useOrganization(pass?.organization_id);
  const { stripe } = useStripeAccount(organization?.id);
  if (!pass || !stripe) return null;

  return (
    <>
      <Heading size="md" marginY={2}>
        Payment for {pass.name}
      </Heading>
      <Text marginY={4}>
        {priceInDollars("USD", pass.price_in_cents)}{" "}
        {CURRENCY_CODE_TO_CURRENCY["USD"].code}
      </Text>
      <Elements
        stripe={stripe}
        options={{
          mode: "subscription",
          amount: pass.price_in_cents,
          currency: "usd",
        }}
      >
        <PassPaymentForm pass={pass} />
      </Elements>
    </>
  );
};
