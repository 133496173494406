import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { UserHome } from "./UserHome";
import { welcomeUrl } from "../../services/routes/urlBuilder";

export const HomeRedirector: React.FC = () => {
  const { currentUser, initialized } = useCurrentUser();
  const navigate = useNavigate();

  if (!initialized) return null;

  if (!currentUser) {
    navigate(welcomeUrl());
  }

  return <UserHome />;
};
