import { useCallback, useEffect, useState } from "react";
import * as api from "../../../services/api";
import { createFriendshipRequest } from "../../../services/api/friendshipRequests";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { FriendshipRequest } from "../../../types/friendship_requests";

export type ExtendedFriendshipRequest = FriendshipRequest & {
  full_name: string;
};

export type UseFriendshipRequests = {
  create: (userId: string) => Promise<ExtendedFriendshipRequest>;
  accept: (userId: string) => Promise<ExtendedFriendshipRequest>;
  ignore: (userId: string) => Promise<ExtendedFriendshipRequest>;

  friendshipRequestIncomingByUserId:
    | Record<string, ExtendedFriendshipRequest>
    | undefined;
  friendshipRequestOutgoingByUserId:
    | Record<string, ExtendedFriendshipRequest>
    | undefined;

  refetch: () => void;
};

export const useFriendshipRequests = (): UseFriendshipRequests => {
  const [
    friendshipRequestIncomingByUserId,
    setFriendshipRequestIncomingByUserId,
  ] = useState<Record<string, ExtendedFriendshipRequest>>();
  const [
    friendshipRequestOutgoingByUserId,
    setFriendshipRequestOutgoingByUserId,
  ] = useState<Record<string, ExtendedFriendshipRequest>>();
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!currentUser) return;

    const requests: ExtendedFriendshipRequest[] =
      await api.friendshipRequests.fetchFriendshipRequests();

    const incoming = requests.reduce(
      (acc, request) => {
        if (request.request_recipient_user_id === currentUser.id) {
          acc[request.request_sender_user_id] = request;
        }
        return acc;
      },
      {} as Record<string, ExtendedFriendshipRequest>
    );

    const outgoing = requests.reduce(
      (acc, request) => {
        if (request.request_sender_user_id === currentUser.id) {
          acc[request.request_recipient_user_id] = request;
        }
        return acc;
      },
      {} as Record<string, ExtendedFriendshipRequest>
    );

    setFriendshipRequestIncomingByUserId(incoming);
    setFriendshipRequestOutgoingByUserId(outgoing);
  }, [currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const create = useCallback(
    async (userId: string) => {
      if (!currentUser) return;
      const friendshipRequest = await createFriendshipRequest(userId);

      setFriendshipRequestOutgoingByUserId((prev) => ({
        ...prev,
        [userId]: friendshipRequest,
      }));

      return friendshipRequest;
    },
    [currentUser]
  );

  const accept = useCallback(
    async (userId: string) => {
      if (!currentUser || !friendshipRequestIncomingByUserId) return;

      const friendshipRequestId = friendshipRequestIncomingByUserId[userId].id;

      const friendshipRequest =
        await api.friendshipRequests.acceptFriendshipRequest(
          friendshipRequestId
        );

      setFriendshipRequestIncomingByUserId((prev) => ({
        ...prev,
        [userId]: friendshipRequest,
      }));

      return friendshipRequest;
    },
    [currentUser, friendshipRequestIncomingByUserId]
  );

  const ignore = useCallback(
    async (userId: string) => {
      if (!currentUser || !friendshipRequestIncomingByUserId) return;

      const friendshipRequestId = friendshipRequestIncomingByUserId[userId].id;

      const friendshipRequest =
        await api.friendshipRequests.ignoreFriendshipRequest(
          friendshipRequestId
        );

      setFriendshipRequestIncomingByUserId((prev) => ({
        ...prev,
        [userId]: friendshipRequest,
      }));

      return friendshipRequest;
    },
    [currentUser, friendshipRequestIncomingByUserId]
  );

  return {
    friendshipRequestIncomingByUserId,
    friendshipRequestOutgoingByUserId,
    create,
    accept,
    ignore,
    refetch: fetch,
  };
};
