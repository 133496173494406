import { Flex, Image, Text } from "@chakra-ui/react";
import { Panel } from "../../../components/Panel";
import { getImageUrl } from "../../../services/images";
import { getHtmlFromString } from "../../../services/formatter";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useOutletContext } from "react-router-dom";

export const OrganizationInfo: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const { showDescription } = useOutletContext<any>();

  const [, ...photoIds] = organization?.photo_ids || [];

  const html = getHtmlFromString(organization?.description);

  if (!showDescription || !organization) return null;

  return (
    <>
      <Panel>
        <Text
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></Text>
      </Panel>

      <Flex flexWrap="wrap" gap={1}>
        {photoIds.map((photoId) => (
          <Image
            key={photoId}
            src={getImageUrl(photoId)}
            alt={organization.name}
            width="calc(50% - 2px)"
            height="200px"
            fit="cover"
            borderRadius="24px"
          />
        ))}
      </Flex>
    </>
  );
};
