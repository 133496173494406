import { Control, FieldErrors, useController } from "react-hook-form";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { selectThemeProps } from "../../../../services/theme/overrides/select";
import { TicketType } from "../../../../types/ticket_types";
import { useOrganization } from "../../../../providers/CurrentOrganizationProvider";

interface TicketTypePaymentTypeProps {
  control: Control<TicketType>;
  errors?: FieldErrors<TicketType>;
  organizationId?: string;
}

export const TicketTypePaymentType: React.FC<TicketTypePaymentTypeProps> = ({
  control,
  errors,
  organizationId,
}) => {
  const { organization } = useOrganization(organizationId);

  const {
    field: {
      onChange: paymentTypeOnChange,
      onBlur: paymentTypeOnBlur,
      value: paymentTypeValue,
      name: paymentTypeName,
      ref: paymentTypeRef,
    },
  } = useController({
    name: "payment_type",
    defaultValue: "free",
    control,
    rules: { required: "Payment type is required" },
  });

  return (
    <>
      <FormControl isInvalid={!!errors?.payment_type} width="100%">
        <FormLabel htmlFor={"payment_type"}>Type</FormLabel>
        <HStack>
          <Tooltip
            label={
              !organization
                ? "Create an organization in order to offer paid events"
                : organization.stripe_account_id === null
                ? "Setup your payment account in order to offer paid events"
                : ""
            }
          >
            <Select
              placeholder="Type"
              isInvalid={!!errors?.payment_type}
              isDisabled={
                !organization || organization.stripe_account_id === null
              }
              name={paymentTypeName}
              ref={paymentTypeRef}
              onChange={paymentTypeOnChange}
              onBlur={paymentTypeOnBlur}
              value={paymentTypeValue}
              {...selectThemeProps}
            >
              <option value="free">Free</option>
              <option value="fixed">Ticketed</option>
              <option value="slider">Sliding scale</option>
            </Select>
          </Tooltip>
        </HStack>
        {errors?.payment_type && (
          <FormErrorMessage>{errors?.payment_type.message}</FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};
