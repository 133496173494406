import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import * as api from "../services/api";
import { OrganizationUser } from "../types/organization_users";

interface OrganizationUserContext {
  currentOrganizationUser: OrganizationUser | undefined;
  refetch: () => void;
}

const CurrentOrganizationUserContext = createContext<OrganizationUserContext>({
  currentOrganizationUser: undefined,
  refetch: () => {},
});

interface CurrentOrganizationUserProviderProps {
  organizationId: string;
  children: React.ReactNode;
}

export const useOrganizationUser = (organizationId: string | undefined) => {
  const { user: authUser } = useAuth();
  const [organizationUser, setOrganizationUser] = useState<
    OrganizationUser | undefined
  >(undefined);

  const fetchOrganizationUser = useCallback(async () => {
    if (authUser && organizationId) {
      const user =
        await api.organizationUsers.fetchCurrentOrganizationUser(
          organizationId
        );
      setOrganizationUser(user);
    } else {
      setOrganizationUser(undefined);
    }
  }, [authUser, organizationId]);

  useEffect(() => {
    fetchOrganizationUser();
  }, [fetchOrganizationUser]);

  return { organizationUser, refetch: fetchOrganizationUser };
};

export const CurrentOrganizationUserProvider = ({
  organizationId,
  children,
}: CurrentOrganizationUserProviderProps) => {
  const { organizationUser, refetch } = useOrganizationUser(organizationId);

  return (
    <CurrentOrganizationUserContext.Provider
      value={{ currentOrganizationUser: organizationUser, refetch }}
    >
      {children}
    </CurrentOrganizationUserContext.Provider>
  );
};

export const useCurrentOrganizationUser = (): OrganizationUserContext => {
  return useContext(CurrentOrganizationUserContext);
};
