import QrScanner from "qr-scanner";
import { useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Box, Heading, HStack, Spacer, Text } from "@chakra-ui/react";
import { Panel } from "../../../components/Panel";
import { BackButton } from "../../../components/BackButton";
import { eventCheckinUrl } from "../../../services/routes/urlBuilder";
import { EventCheckinContext } from "./EventCheckInParent";
import {
  ALERT_GREEN_COLOR,
  ALERT_RED_COLOR,
  DEFAULT_THEME_PRIMARY_COLOR,
} from "../../../services/theme/colors";
import { hexToRgbWithOpacity } from "../../../services/formatter";
import { UserAvatar } from "../../../components/UserAvatar";
import { TicketsCheckin } from "../../../hooks/useTicketsCheckin";

const audio = new Audio(
  "https://storage.googleapis.com/vianiko-public-files-prod/sounds/scanner-beep.mp3"
);

export const EventCheckinScan: React.FC = () => {
  const { eventId } = useParams();
  const { checkinByTicketId, onChangeCheckin } =
    useOutletContext() as EventCheckinContext;
  const [scanStatus, setScanStatus] = useState<
    "scanning" | "success" | "checked_in" | "invalid"
  >("scanning");
  const [scanResult, setScanResult] = useState<QrScanner.ScanResult>();
  const [currentCheckin, setCurrentCheckin] = useState<TicketsCheckin>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const currentCheckinTimoutRef = useRef<NodeJS.Timeout | null>(null);
  const scanStatusTimoutRef = useRef<NodeJS.Timeout | null>(null);

  const numCheckedIn = useMemo(() => {
    return Object.values(checkinByTicketId).filter(
      (ticket) => ticket.status === "checked_in"
    ).length;
  }, [checkinByTicketId]);

  useEffect(() => {
    if (!scanResult?.data) return;

    const checkin = checkinByTicketId[scanResult.data];
    if (
      checkin?.ticket_id !== undefined &&
      checkin.ticket_id === currentCheckin?.ticket_id
    ) {
      return;
    } else if (checkin) {
      setCurrentCheckin(checkin);
    }

    if (checkin?.status === "active") {
      setScanStatus("success");
      onChangeCheckin(scanResult.data, "checked_in");
    } else if (checkin?.status === "checked_in") {
      setScanStatus("checked_in");
    } else {
      setScanStatus("invalid");
    }

    audio.currentTime = 0;
    audio.play();

    if (currentCheckinTimoutRef.current) {
      clearTimeout(currentCheckinTimoutRef.current);
    }

    if (scanStatusTimoutRef.current) {
      clearTimeout(scanStatusTimoutRef.current);
    }

    scanStatusTimoutRef.current = setTimeout(() => {
      setScanStatus("scanning");
    }, 3000);

    currentCheckinTimoutRef.current = setTimeout(() => {
      setCurrentCheckin(undefined);
    }, 20000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanResult, checkinByTicketId]);

  useEffect(() => {
    if (!videoRef.current || !overlayRef.current) return;

    const qrScanner = new QrScanner(
      videoRef.current,
      (result) => setScanResult(result),
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: overlayRef.current,
      }
    );

    qrScanner.start();

    return () => {
      qrScanner.destroy();
    };
  }, [videoRef]);

  if (!eventId) return null;

  return (
    <>
      <BackButton to={eventCheckinUrl(eventId)} />
      <Panel>
        <Heading size="sm">Scan tickets</Heading>
        <Text marginY={2}>
          {numCheckedIn} / {Object.keys(checkinByTicketId).length} checked in
        </Text>

        <video ref={videoRef}></video>
        <Box
          ref={overlayRef}
          background={
            !!scanResult
              ? scanStatus === "invalid"
                ? hexToRgbWithOpacity(ALERT_RED_COLOR, 0.5)
                : scanStatus === "checked_in"
                  ? hexToRgbWithOpacity(ALERT_RED_COLOR, 0.5)
                  : scanStatus === "success"
                    ? hexToRgbWithOpacity(ALERT_GREEN_COLOR, 0.5)
                    : "transparent"
              : "transparent"
          }
          borderWidth="5px"
          border={
            scanResult
              ? scanStatus === "invalid"
                ? `5px solid ${hexToRgbWithOpacity(ALERT_RED_COLOR, 0.9)}`
                : scanStatus === "checked_in"
                  ? `5px solid ${hexToRgbWithOpacity(ALERT_RED_COLOR, 0.9)}`
                  : scanStatus === "success"
                    ? `5px solid ${hexToRgbWithOpacity(ALERT_GREEN_COLOR, 0.9)}`
                    : `1px solid ${hexToRgbWithOpacity(
                        DEFAULT_THEME_PRIMARY_COLOR,
                        0.4
                      )}`
              : `1px solid ${hexToRgbWithOpacity(
                  DEFAULT_THEME_PRIMARY_COLOR,
                  0.4
                )}`
          }
        ></Box>

        <Box textAlign="center">
          {scanStatus === "invalid" ? (
            <Heading size="lg" color={ALERT_RED_COLOR}>
              NOT A VALID TICKET
            </Heading>
          ) : scanStatus === "checked_in" ? (
            <Heading size="lg" color={ALERT_RED_COLOR}>
              ALREADY CHECKED IN
            </Heading>
          ) : scanStatus === "success" ? (
            <Heading size="lg" color="green">
              CHECKED IN
            </Heading>
          ) : null}

          {!!currentCheckin && (
            <HStack width="100%" marginTop={4}>
              <UserAvatar user={currentCheckin} />
              <Box textAlign="left">
                <Text>
                  {currentCheckin.first_name} {currentCheckin.last_name}
                </Text>
                <Text>{currentCheckin.ticket_type_name}</Text>
              </Box>
              <Spacer />
            </HStack>
          )}
        </Box>
      </Panel>
    </>
  );
};
