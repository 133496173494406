import { ConfirmationResult } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link, Text } from "@chakra-ui/react";
import { PhoneNumber } from "./components/PhoneNumber";
import { OneTimePassword } from "./components/OneTimePassword";
import { homeUrl, userEditUrl } from "../../services/routes/urlBuilder";
import { Panel } from "../../components/Panel";
import { useCurrentUser } from "../../providers/CurrentUserProvider";

export const PhoneAuth: React.FC = () => {
  const [confirmationResult, setConfirmationResult] =
    React.useState<ConfirmationResult>();
  let [searchParams] = useSearchParams();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const redirectParam = searchParams.get("redirect");
  const redirectTo = redirectParam
    ? decodeURIComponent(redirectParam)
    : homeUrl();

  const authReasonParam = searchParams.get("authReason");
  const signInForTicketPurchase = authReasonParam
    ? authReasonParam === "ticket_purchase"
    : false;

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.full_name) {
        navigate(userEditUrl(currentUser.id, redirectTo));
        return;
      }

      navigate(redirectTo);
    }
  }, [currentUser, navigate, redirectTo]);

  const handleSubmitPhone = (result: ConfirmationResult) => {
    setConfirmationResult(result);
  };

  return (
    <>
      <Panel>
        <PhoneNumber
          title={
            signInForTicketPurchase ? "Sign in to get your tickets" : "Sign in"
          }
          onSubmit={handleSubmitPhone}
          confirmationResult={confirmationResult}
        />
        {confirmationResult && (
          <OneTimePassword confirmationResult={confirmationResult} />
        )}
        <Text variant="secondary" marginTop={10}>
          By signing up, you accept the{" "}
          <Link target="_blank" href="https://info.feather.rsvp/privacy-policy">
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            target="_blank"
            href="https://info.feather.rsvp/terms-of-service"
          >
            Terms of Service
          </Link>
        </Text>
      </Panel>
    </>
  );
};
