import React, { useRef } from "react";
import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ParsedCountry, PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { BiCheck } from "react-icons/bi";

const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

interface PhoneNumberProps {
  title: string;
  onSubmit: (confirmationResult: ConfirmationResult) => void;
  confirmationResult: ConfirmationResult | undefined;
}

interface FormValues {
  phone: string;
}

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const PhoneNumber: React.FC<PhoneNumberProps> = ({
  title,
  onSubmit,
  confirmationResult,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
  } = useForm<FormValues>({ mode: "onBlur" });

  const [auth, setAuth] = React.useState<any>();
  const [countryCode, setCountryCode] = React.useState<string>("1");
  const [inputValue, setInputValue] = React.useState<string>("");
  const [recaptchaVerifier, setRecaptchaVerifier] =
    React.useState<RecaptchaVerifier>();

  const recaptchaRef = useRef(null);

  React.useEffect(() => {
    const auth = getAuth();
    setAuth(auth);
  }, []);

  const handleChangePhone = (
    value: string,
    meta: { country: ParsedCountry; inputValue: string }
  ) => {
    setCountryCode(meta.country.dialCode);
    setInputValue(meta.inputValue);
    setValue("phone", `+${meta.country.dialCode}${meta.inputValue}`);
    clearErrors();
  };

  const handleSubmitPhone: SubmitHandler<FormValues> = () => {
    if (!recaptchaRef.current) return;

    const phone = `+${countryCode}${inputValue}`;
    if (!isPhoneValid(phone)) {
      setError("phone", {
        message: "Enter a valid phone number",
      });
      return;
    }

    const rVerifier = new RecaptchaVerifier(auth, recaptchaRef.current, {
      size: "invisible",
      "error-callback": (error: any) => {
        console.error("recaptcha error", error);
        setError("phone", {
          message: "Something went wrong, please try again later",
        });
        recaptchaVerifier?.clear();
      },
      "expired-callback": (error: any) => {
        console.log("expired error", error);
        setError("phone", {
          message: "Something went wrong, please try again later",
        });
        recaptchaVerifier?.clear();
      },
    });

    setRecaptchaVerifier(rVerifier);
    signInWithPhoneNumber(auth, phone, rVerifier)
      .then((confirmationResult) => {
        onSubmit(confirmationResult);
      })
      .catch((error) => {
        setError("phone", {
          message: "Something went wrong, please try again later",
        });
        recaptchaVerifier?.clear();
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitPhone)}>
        <Heading size="xl" marginTop={2}>
          {title}
        </Heading>
        <Text size="md" marginBottom={10}>
          Enter your phone number
        </Text>
        <FormControl isInvalid={!!errors.phone} width="100%">
          <HStack>
            <PhoneInput
              {...register("phone")}
              defaultCountry="us"
              disabled={!!confirmationResult}
              onChange={handleChangePhone}
              disableDialCodeAndPrefix
              countrySelectorStyleProps={{
                buttonStyle: {
                  borderRadius: "24px 0px 0px 24px",
                  border: !!confirmationResult ? "0px" : "2px solid",
                  borderRight: "none",
                  paddingLeft: "16px",
                  paddingRight: "8px",
                  height: "48px",
                  opacity: !!confirmationResult ? 0.8 : 1,
                },
              }}
              inputStyle={{
                borderRadius: "0px 24px 24px 0px",
                border: !!confirmationResult ? "0px" : "2px solid",
                borderLeft: "none",
                width: "100%",
                height: "48px",
                fontSize: "17px",
                fontWeight: "bold",
                opacity: !!confirmationResult ? 0.8 : 1,
              }}
              style={{ flexGrow: 1 }}
            />
            {!!confirmationResult ? (
              <Button size="lg" padding={0}>
                <Icon as={BiCheck} />
              </Button>
            ) : (
              <Button
                ref={recaptchaRef}
                className="g-recaptcha"
                type="submit"
                variant="primary"
                size="lg"
              >
                Get SMS
              </Button>
            )}
          </HStack>
          {errors.phone && (
            <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
          )}
        </FormControl>
      </form>
    </>
  );
};
