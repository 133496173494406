import { ButtonLink } from "../../../components/ButtonLink";
import { waiverNewUrl } from "../../../services/routes/urlBuilder";
import { Panel } from "../../../components/Panel";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { useWaivers } from "../../../hooks/useWaivers";
import { WaiverItem } from "./WaiverItem";
import { Outlet, useOutletContext } from "react-router-dom";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";

export const WaiversList: React.FC = () => {
  const { showWaivers } = useOutletContext<any>();

  const { organization } = useCurrentOrganization();
  const { currentOrganizationUser } = useCurrentOrganizationUser();

  const { waivers, refetch } = useWaivers(organization?.id);

  const handleDelete = async () => {
    await refetch();
  };

  if (!showWaivers || !organization) return null;

  return (
    <>
      <Panel>
        {currentOrganizationUser?.is_owner && (
          <ButtonLink
            to={waiverNewUrl(organization.id)}
            buttonProps={{
              size: "lg",
              variant: "outline",
              width: "100%",
              marginBottom: 4,
            }}
          >
            Add Waiver
          </ButtonLink>
        )}
        {waivers?.map((waiver) => (
          <WaiverItem key={waiver.id} waiver={waiver} onDelete={handleDelete} />
        ))}
      </Panel>

      <Outlet />
    </>
  );
};
