import { Heading } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import { EventForm } from "./components/forms/EventForm";
import { VianikoEvent } from "../../types/events";
import * as api from "../../services/api";
import { useVianikoEvent } from "../../hooks/useVianikoEvent";
import { eventShowUrl } from "../../services/routes/urlBuilder";
import { PageHeader } from "../../components/PageHeader";
import { PageBreadcrumb } from "../../components/PageBreadcrumb";

export const EditEventPage: React.FC = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const event = useVianikoEvent(eventId);

  const handleSubmit: SubmitHandler<VianikoEvent> = async (data) => {
    if (!eventId) return;

    const event = await api.events.updateEvent(eventId, data);
    navigate(eventShowUrl(event.id));
  };

  if (!event) return null;

  return (
    <>
      <PageHeader
        breadcrumbs={
          <PageBreadcrumb to={eventShowUrl(event.id)} label={event.name} />
        }
      />

      <Heading as="h1" size="lg" marginLeft={5} marginBottom={2}>
        Edit Event
      </Heading>
      <EventForm defaultValues={event} onSubmit={handleSubmit} hideRecurring />
    </>
  );
};
