import { useParams } from "react-router-dom";
import { PassSubscriptionPayment } from "./PassSubscriptionPayment";
import { usePass } from "../passes/hooks/usePass";
import { PassOneTimePayment } from "./PassOneTimePayment";

export const NewPassPaymentPage: React.FC = () => {
  const { passId } = useParams();
  const { pass } = usePass(passId);

  if (!pass) return null;

  return pass.is_subscription ? (
    <PassSubscriptionPayment pass={pass} />
  ) : (
    <PassOneTimePayment pass={pass} />
  );
};
