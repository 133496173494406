import { Control, useController } from "react-hook-form";
import { NumberField } from "../../../components/forms/NumberField";
import { Checkbox, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface MembershipConfigurationSectionProps {
  control: Control<any>;
  passId?: string;
}

export const MembershipConfigurationSection: React.FC<
  MembershipConfigurationSectionProps
> = ({ control, passId }) => {
  const [hasUsageLimit, setHasUsageLimit] = useState(false);

  const {
    field: { value: numMonthsActive, onChange: onChangeNumMonthsActive },
    fieldState: { error: numMonthsActiveError },
  } = useController({
    name: "num_months_active",
    control,
    defaultValue: 1,
  });

  const {
    field: { value: numCredits, onChange: onChangeNumCredits },
    fieldState: { error: numCreditsError },
  } = useController({
    name: "num_credits",
    control,
    defaultValue: 0,
  });

  const {
    field: { onChange: onChangeIsUnlimited },
  } = useController({
    name: "is_unlimited",
    control,
    defaultValue: true,
  });

  useController({
    name: "is_subscription",
    control,
    defaultValue: true,
  });

  useController({
    name: "type",
    control,
    defaultValue: "membership",
  });

  useEffect(() => {
    if (!passId) {
      onChangeNumCredits(0);
      setHasUsageLimit(false);
    } else {
      setHasUsageLimit(numCredits > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeUsageLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasUsageLimit(e.target.checked);
    if (e.target.checked) {
      onChangeNumCredits(1);
      onChangeIsUnlimited(false);
    } else {
      onChangeNumCredits(null);
      onChangeIsUnlimited(true);
    }
  };

  return (
    <VStack alignItems="stretch" gap={4}>
      <Text>Renewal interval</Text>
      <HStack>
        <NumberField
          value={numMonthsActive}
          onChange={onChangeNumMonthsActive}
          error={numMonthsActiveError?.message}
          min={1}
        />
        <Text>months</Text>
      </HStack>

      <HStack>
        <Checkbox isChecked={hasUsageLimit} onChange={handleChangeUsageLimit} />
        <Text>Usage limit</Text>
      </HStack>

      {hasUsageLimit && (
        <HStack>
          <NumberField
            value={numCredits}
            onChange={onChangeNumCredits}
            error={numCreditsError?.message}
            min={1}
          />
          <Text>events per renewal</Text>
        </HStack>
      )}
    </VStack>
  );
};
