import { VianikoEvent } from "../../../types/events";
import { useEffect, useMemo, useState } from "react";
import { fetchEvent } from "../../../services/api/events";
import { useOutletContext, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { UserAvatar } from "../../../components/UserAvatar";
import { Panel } from "../../../components/Panel";
import { StyledInput } from "../../../components/forms/StyledInput";
import {
  eventScanUrl,
  eventShowUrl,
} from "../../../services/routes/urlBuilder";
import { BackButton } from "../../../components/BackButton";
import { ButtonLink } from "../../../components/ButtonLink";
import { EventCheckinContext } from "./EventCheckInParent";

export const EventCheckinList: React.FC = () => {
  const { checkinByTicketId, onChangeCheckin } =
    useOutletContext() as EventCheckinContext;

  const [event, setEvent] = useState<VianikoEvent>();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { eventId } = useParams();

  const searchedTickets = useMemo(() => {
    const filteredCheckinByTicketId = Object.entries(checkinByTicketId)
      .filter(([ticketId, ticket]) =>
        `${ticket.first_name} ${ticket.last_name}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
      .reduce(
        (acc, [ticketId, ticket]) => {
          acc[ticketId] = ticket;
          return acc;
        },
        {} as Record<string, (typeof checkinByTicketId)[string]>
      );

    return filteredCheckinByTicketId;
  }, [checkinByTicketId, searchQuery]);

  const numCheckedIn = useMemo(() => {
    return Object.values(checkinByTicketId).filter(
      (ticket) => ticket.status === "checked_in"
    ).length;
  }, [checkinByTicketId]);

  useEffect(() => {
    const init = async () => {
      if (!eventId) return;

      const { event } = await fetchEvent(eventId);

      setEvent(event);
    };

    init();
  }, [eventId]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  if (!event) return null;

  return (
    <>
      <BackButton to={eventShowUrl(event.id)} />

      <Panel>
        <Heading as="h1" size="lg">
          {event.name}
        </Heading>

        <ButtonLink
          to={eventScanUrl(event.id)}
          buttonProps={{ variant: "primary", width: "100%", marginY: 2 }}
        >
          Scan tickets
        </ButtonLink>

        <Text>
          {numCheckedIn} / {Object.keys(checkinByTicketId).length} checked in
        </Text>

        <StyledInput
          name="search"
          onChange={handleSearch}
          isRequired
          inputProps={{ marginY: 2, placeholder: "Search by name" }}
        />

        <VStack>
          {Object.entries(
            !!searchQuery ? searchedTickets : checkinByTicketId
          ).map(([ticketId, ticket]) => (
            <HStack key={ticketId} width="100%">
              <UserAvatar user={ticket} />

              <Box>
                <Text>
                  {ticket.first_name} {ticket.last_name}
                </Text>
                <Text>{ticket.ticket_type_name}</Text>
              </Box>
              <Spacer />

              <Button
                variant={ticket.status === "checked_in" ? "ghost" : "primary"}
                onClick={() => onChangeCheckin(ticketId, ticket.status)}
              >
                {ticket.status === "checked_in" ? "Check out" : "Check in"}
              </Button>
            </HStack>
          ))}
        </VStack>
      </Panel>
    </>
  );
};
