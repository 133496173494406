import { useCallback, useEffect, useState } from "react";
import { fetchCurrentUserConnections } from "../services/api/connections";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { Connection } from "../types/connections";

interface UseCurrentUserConnectionsResult {
  userConnections: Connection[] | undefined;
}

export const useCurrentUserConnections =
  (): UseCurrentUserConnectionsResult => {
    const [userConnections, setUserConnections] = useState<
      Connection[] | undefined
    >();
    const { currentUser } = useCurrentUser();

    const fetch = useCallback(async () => {
      if (!currentUser) return;
      const userConnections = await fetchCurrentUserConnections();
      setUserConnections(userConnections);
    }, [currentUser]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    return { userConnections };
  };
