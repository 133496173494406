import { addDays, addHours, roundToNearestHours } from "date-fns";
import { format, fromZonedTime, toZonedTime } from "date-fns-tz";
import { TimezoneOption, timezoneOptions } from "./timezoneOptions";

export default class ZonedDateTime {
  private iana_timezone: string;
  private utcDate: Date;

  constructor(utcDate: Date, iana_timezone: string) {
    ZonedDateTime.validateTimezone(iana_timezone);
    ZonedDateTime.validateDate(utcDate);

    this.utcDate = utcDate;
    this.iana_timezone = iana_timezone;
  }

  // format of utcIOSDateTime: "2021-08-01T00:00:00Z"
  static fromUtc(utcISODateTime: string, iana_timezone: string): ZonedDateTime {
    return new ZonedDateTime(new Date(utcISODateTime), iana_timezone);
  }

  static now(): ZonedDateTime {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const local = new Date();
    const utc = fromZonedTime(local, timezone);
    return new ZonedDateTime(utc, timezone);
  }

  static fromZonedDateTime(
    zdt: ZonedDateTime,
    add_days: number = 0,
    add_hours: number = 0
  ): ZonedDateTime {
    const newDT = new ZonedDateTime(zdt.getUtcDate(), zdt.getTimezone());
    newDT.addDays(add_days);
    newDT.addHours(add_hours);
    return newDT;
  }

  setTimezone(new_iana_timezone: string): void {
    ZonedDateTime.validateTimezone(new_iana_timezone);
    this.iana_timezone = new_iana_timezone;
  }

  getTimezone(): string {
    return this.iana_timezone;
  }

  setDate(isoDate: string): void {
    ZonedDateTime.validateDate(new Date(isoDate));
    const localDate = toZonedTime(this.utcDate, this.iana_timezone);

    const newDate = new Date(isoDate);
    localDate.setFullYear(newDate.getFullYear());
    localDate.setMonth(newDate.getMonth());
    localDate.setDate(newDate.getUTCDate());
    this.utcDate = fromZonedTime(localDate, this.iana_timezone);
  }

  addDays(amount: number): void {
    ZonedDateTime.validateNumber(amount);
    const localDate = toZonedTime(this.utcDate, this.iana_timezone);
    const newDate = addDays(localDate, amount);
    this.utcDate = fromZonedTime(newDate, this.iana_timezone);
  }

  addHours(amount: number): void {
    ZonedDateTime.validateNumber(amount);
    const localDate = toZonedTime(this.utcDate, this.iana_timezone);
    const newDate = addHours(localDate, amount);
    this.utcDate = fromZonedTime(newDate, this.iana_timezone);
  }

  roundToNearestHours(): void {
    const localDate = toZonedTime(this.utcDate, this.iana_timezone);
    const roundedDate = roundToNearestHours(localDate);
    this.utcDate = fromZonedTime(roundedDate, this.iana_timezone);
  }

  setTime(isoTime: string): void {
    ZonedDateTime.validateTimeFormat(isoTime);

    const localDate = toZonedTime(this.utcDate, this.iana_timezone);
    const [hours, minutes] = isoTime.split(":").map((n) => parseInt(n, 10));
    localDate.setHours(hours);
    localDate.setMinutes(minutes);
    this.utcDate = fromZonedTime(localDate, this.iana_timezone);
  }

  toDisplayDate(): string {
    const localDate = toZonedTime(this.utcDate, this.iana_timezone);
    const output = format(localDate, "yyyy-MM-dd", {
      timeZone: this.iana_timezone,
    });
    return output;
  }

  toDisplayTime(): string {
    const localDate = toZonedTime(this.utcDate, this.iana_timezone);
    return `${this.zeroPad(localDate.getHours())}:${this.zeroPad(localDate.getMinutes())}`;
  }

  toDisplayTimezone(): string {
    return this.iana_timezone;
  }

  toDisplayAbbrTimezone(): string {
    const opt: TimezoneOption | undefined = timezoneOptions.find(
      (opt) => opt.iana_timezone === this.iana_timezone
    );
    return opt ? opt.abbreviation : "";
  }

  toISOUtc(): string {
    return this.utcDate.toISOString();
  }

  getUtcDate(): Date {
    return this.utcDate;
  }

  private zeroPad(number: number): string {
    return number.toString().padStart(2, "0");
  }

  static validateTimezone(iana_timezone: string): void {
    if (!timezoneOptions.find((opt) => opt.iana_timezone === iana_timezone)) {
      throw new Error(`Invalid timezone: ${iana_timezone}`);
    }
  }

  static validateUtcDateTime(utcDateTime: string): void {
    if (isNaN(new Date(utcDateTime).getTime())) {
      throw new Error(`Invalid UTC date format: ${utcDateTime}`);
    }
  }

  static validateDate(date: Date): void {
    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date: ${date}`);
    }
  }

  static validateNumber(value: number): void {
    if (Number.isFinite(value) === false) {
      throw new Error(`Invalid number: ${value}`);
    }
  }

  static validateTimeFormat(isoTime: string): void {
    const timeRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
    const isValid = timeRegex.test(isoTime);
    if (!isValid) {
      throw new Error(`Invalid time format: ${isoTime}`);
    }
  }
}
