import {
  Box,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { usePass } from "../hooks/usePass";
import { Panel } from "../../../components/Panel";
import { passPaymentUrl } from "../../../services/routes/urlBuilder";
import { ButtonLink } from "../../../components/ButtonLink";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { priceInDollars } from "../../../services/payments";
import { PassAboutText } from "../../organizations/components/PassAboutText";

interface PurchasePassModalProps {
  passId: string;
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const PurchasePassModal: React.FC<PurchasePassModalProps> = ({
  passId,
  eventId,
  isOpen,
  onClose,
}) => {
  const { pass } = usePass(passId);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <HStack margin={4}>
          <Heading size="lg" as="h2">
            Buy a pass
          </Heading>
          <ModalCloseButton marginTop={2} />
        </HStack>

        {pass && (
          <ModalBody paddingTop={0}>
            <Panel>
              <HStack align="start">
                <Box>
                  <Heading as="h3" size="md">
                    {pass.name}
                  </Heading>

                  <PassAboutText pass={pass} />

                  {pass.description && (
                    <Text size="md" color={TEXT_SECONDARY_COLOR}>
                      {pass.description}
                    </Text>
                  )}
                </Box>

                <Spacer />

                <HStack>
                  <Text size="md" color={TEXT_SECONDARY_COLOR}>
                    {priceInDollars("USD", pass.price_in_cents)}
                  </Text>
                </HStack>
              </HStack>
            </Panel>

            <ButtonLink
              to={passPaymentUrl(passId, eventId)}
              buttonProps={{
                variant: "primary",
                size: "lg",
                width: "100%",
                marginTop: 4,
                marginBottom: 8,
              }}
            >
              Buy pass
            </ButtonLink>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
