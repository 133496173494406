import { useSearchParams } from "react-router-dom";
import { Heading, HStack, Spacer, Text } from "@chakra-ui/react";
import { InviteFriendsModal } from "../../../components/InviteFriendsModal";
import { inviteUserToEvent } from "../../../services/api/events";
import { VianikoEvent } from "../../../types/events";
import { eventShowUrl } from "../../../services/routes/urlBuilder";
import { UserAvatar } from "../../../components/UserAvatar";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useCurrentEventUser } from "../../../providers/CurrentEventUserProvider";
import {
  Permission,
  PermissionDropdown,
} from "../../../components/PermissionDropdown";
import { updateEventUser } from "../../../services/api/eventUsers";
import { useAttendees } from "../hooks/useAttendees";

interface EventInviteFriendsModalProps {
  event: VianikoEvent;
  isOpen: boolean;
  onClose: () => void;
}

export const EventInviteFriendsModal: React.FC<
  EventInviteFriendsModalProps
> = ({ event, isOpen, onClose }) => {
  const { currentUser } = useCurrentUser();
  const { currentEventUser, refetch } = useCurrentEventUser();
  const { attendees } = useAttendees(event.id);
  const [searchParams] = useSearchParams();

  const isWelcome = searchParams.get("welcome") === "true";

  const handleInvite = async (userIds: string[]) => {
    await Promise.all(
      userIds.map((userId) => inviteUserToEvent(event.id, userId))
    );
  };

  const handleChangePermission = async (value: Permission) => {
    if (!currentEventUser) return;

    await updateEventUser(currentEventUser.id, {
      findable: value === "everyone",
    });
    await refetch();
  };

  return (
    <InviteFriendsModal
      isOpen={isOpen}
      inviteLink={`${process.env.REACT_APP_BASE_URL}${eventShowUrl(event.id)}`}
      onClose={onClose}
      onInvite={handleInvite}
      heading="You're going!"
      excludeUserIds={attendees?.map((attendee) => attendee.id)}
      renderModalBodyTop={
        isWelcome && (
          <>
            <Heading size="sm">Who can see you on the event page?</Heading>
            <HStack marginY={2}>
              <UserAvatar user={currentUser} />
              <Text>{currentUser?.full_name}</Text>

              <Spacer />

              <PermissionDropdown
                value={currentEventUser?.findable ? "everyone" : "me"}
                onChange={handleChangePermission}
              />
            </HStack>
          </>
        )
      }
    />
  );
};
