import { Box, Flex, Text } from "@chakra-ui/react";

interface LandingPageSectionProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

export const LandingPageFeatureBlock: React.FC<LandingPageSectionProps> = ({
  title,
  description,
  icon,
}) => {
  return (
    <Box maxW={300}>
      <Flex direction="row" gap={3}>
        <Box mt={1}>{icon}</Box>
        <Flex direction="column" gap={1}>
          <Text size="md" fontWeight={"bold"}>
            {title}
          </Text>
          <Text variant="secondary">{description}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};
