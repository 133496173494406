import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
} from "@chakra-ui/react";
import { ConfirmationResult, UserCredential } from "firebase/auth";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { StyledRegisteredInput } from "../../../components/forms/StyledRegisteredInput";

interface PhoneNumberProps {
  onSubmit?: (user: UserCredential) => void;
  confirmationResult: ConfirmationResult;
}

interface FormValues {
  otp: string;
}

export const OneTimePassword: React.FC<PhoneNumberProps> = ({
  onSubmit,
  confirmationResult,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>({ mode: "onBlur" });

  const handleSubmitOTP: SubmitHandler<FormValues> = (data) => {
    confirmationResult
      ?.confirm(data.otp)
      .then((result) => {
        if (onSubmit) {
          onSubmit(result);
        }
      })
      .catch((error) => {
        console.log("OTP error", error);
        console.log("OTP error code", error.code);
        console.log("form data", data);
        setError("otp", { message: "Incorrect one time password" });
      });
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitOTP)}>
      <HStack align="top" gap={1}>
        <FormControl isInvalid={!!errors.otp} width="sm" flexGrow={1}>
          <StyledRegisteredInput
            type="number"
            name="otp"
            register={register}
            options={{ minLength: 6, maxLength: 6, required: true }}
            inputProps={{
              placeholder: "Code from SMS",
              size: "lg",
              marginTop: 4,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
          {errors.otp && (
            <FormErrorMessage>
              {errors.otp.message || "Code must be 6 digits"}
            </FormErrorMessage>
          )}
        </FormControl>
        <Button type="submit" variant="primary" size="lg" marginTop={4}>
          Sign in
        </Button>
      </HStack>
    </form>
  );
};
