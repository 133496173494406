import { useForm } from "react-hook-form";
import { Pass } from "../../../types/passes";
import { Panel } from "../../../components/Panel";
import { StyledTextarea } from "../../../components/forms/StyledTextarea";
import { StyledControlledInput } from "../../../components/forms/StyledControlledInput";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DollarsInput } from "../../../components/forms/DollarsInput";
import { useParams } from "react-router-dom";
import { useRecurringEvents } from "../../../hooks/useRecurringEvents";
import { PassConfigurationSection } from "./PassConfigurationSection";
import { MembershipConfigurationSection } from "./MembershipConfigurationSection";
import { VianikoEvent } from "../../../types/events";
import { useEffect, useMemo, useState } from "react";
import { fetchNonRecurringEvents } from "../../../services/api/organizations";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";

interface PassFormProps {
  onSubmit: (data: Pass) => void;
  defaultValues?: Pass;
}

export const PassForm: React.FC<PassFormProps> = ({
  onSubmit,
  defaultValues,
}) => {
  const { organizationId } = useParams();
  const { currentUser } = useCurrentUser();
  const { recurringEvents } = useRecurringEvents(organizationId);
  const [nonRecurringEvents, setNonRecurringEvents] = useState<
    VianikoEvent[] | undefined
  >(undefined);

  useEffect(() => {
    const init = async () => {
      if (!organizationId || !currentUser) return;

      const events = await fetchNonRecurringEvents(organizationId);

      setNonRecurringEvents(events);
    };

    init();
  }, [organizationId, currentUser]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm<Pass>({
    mode: "onBlur",
    defaultValues,
  });

  const passId = watch("id");
  const eventAndRecurringEventIds = watch("event_ids");
  const isSubscription = watch("is_subscription");

  const eventsAndRecurringEvents = useMemo(() => {
    return recurringEvents && nonRecurringEvents
      ? [...recurringEvents, ...nonRecurringEvents]
      : undefined;
  }, [nonRecurringEvents, recurringEvents]);

  useEffect(() => {
    if (!passId && eventsAndRecurringEvents && setValue) {
      setValue(
        "event_ids",
        eventsAndRecurringEvents.map((e) => e.id)
      );
    }
  }, [passId, setValue, eventsAndRecurringEvents]);

  const handleChangeValidFor = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("event_ids", [
        ...(getValues("event_ids") || []),
        e.target.value,
      ]);
    } else {
      setValue(
        "event_ids",
        (getValues("event_ids") || []).filter((id) => id !== e.target.value)
      );
    }
  };

  if (!recurringEvents || !eventAndRecurringEventIds) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel>
        <VStack gap={4}>
          <StyledControlledInput
            name="name"
            control={control}
            label="Name"
            type="text"
            rules={{ required: "This field is required" }}
          />
          <StyledTextarea
            name="description"
            register={register}
            label="Details"
            error={errors.description}
          />
          <DollarsInput name="price_in_cents" label="Price" control={control} />
        </VStack>
      </Panel>

      <Panel>
        <FormControl marginBottom={4}>
          <HStack gap={2}>
            <FormLabel margin={0}>Single pass</FormLabel>

            <Switch isDisabled={!!passId} {...register("is_subscription")} />

            <FormLabel margin={0}>Recurring membership</FormLabel>
          </HStack>
        </FormControl>

        {isSubscription ? (
          <MembershipConfigurationSection passId={passId} control={control} />
        ) : (
          <PassConfigurationSection passId={passId} control={control} />
        )}
      </Panel>

      <Panel>
        <VStack alignItems="stretch">
          <Text>Valid for</Text>
          <CheckboxGroup defaultValue={eventAndRecurringEventIds}>
            {eventsAndRecurringEvents?.map((event) => (
              <Checkbox
                key={event.id}
                value={event.id}
                onChange={handleChangeValidFor}
              >
                {event.name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </VStack>
      </Panel>

      <Button
        type="submit"
        variant="primary"
        size="lg"
        width="100%"
        marginTop={6}
        marginBottom={12}
      >
        Save
      </Button>
    </form>
  );
};
