import { Button, Heading, StyleProps } from "@chakra-ui/react";
import * as api from "../../../services/api";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useNavigate } from "react-router-dom";
import {
  authUrl,
  organizationShowUrl,
} from "../../../services/routes/urlBuilder";

interface JoinCommunityButtonProps extends StyleProps {
  organizationId: string;
  onComplete: () => void;
}

export const JoinCommunityButton: React.FC<JoinCommunityButtonProps> = ({
  organizationId,
  onComplete,
  ...styleProps
}) => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const joinCommunity = async () => {
    if (currentUser) {
      await api.organizationUsers.create(organizationId);
      onComplete();
    } else {
      navigate(authUrl(organizationShowUrl(organizationId)));
    }
  };

  return (
    <Button variant="primary" onClick={joinCommunity} {...styleProps}>
      <Heading as="h3" size="sm">
        Join organization
      </Heading>
    </Button>
  );
};
