import { useCallback, useEffect, useState } from "react";
import { Pass } from "../../../types/passes";
import {
  fetchCurrentUserPasses,
  fetchPasses,
} from "../../../services/api/passes";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { UserPass } from "../../../types/user_passes";

interface UsePassesResult {
  passes: Pass[];
  currentUserPasses: UserPass[];
  refetchPasses: () => Promise<void>;
}

export const usePasses = (
  organizationId: string | undefined
): UsePassesResult => {
  const [passes, setPasses] = useState<Pass[]>([]);
  const [currentUserPasses, setCurrentUserPasses] = useState<UserPass[]>([]);
  const { currentUser } = useCurrentUser();

  const fetchAllPasses = useCallback(async () => {
    if (!organizationId || !currentUser) return;

    const passes = await fetchPasses(organizationId);
    setPasses(passes);
  }, [organizationId, currentUser]);

  const fetchCurrentUser = useCallback(async () => {
    if (!organizationId || !currentUser) return;

    const { user_passes } = await fetchCurrentUserPasses(organizationId);
    setCurrentUserPasses(user_passes);
  }, [organizationId, currentUser]);

  useEffect(() => {
    fetchAllPasses();
    fetchCurrentUser();
  }, [fetchAllPasses, fetchCurrentUser]);

  return { passes, currentUserPasses, refetchPasses: fetchAllPasses };
};
