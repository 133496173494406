interface Currency {
  code: string;
  symbol: string;
}

const USD: Currency = {
  code: "USD",
  symbol: "$",
};

const AUD: Currency = {
  code: "AUD",
  symbol: "A$",
};

const CAD: Currency = {
  code: "CAD",
  symbol: "C$",
};

export const CURRENCIES: Currency[] = [USD, AUD, CAD];

export const CURRENCY_CODE_TO_CURRENCY: { [code: string]: Currency } =
  CURRENCIES.reduce(
    (acc, currency) => {
      acc[currency.code] = currency;
      return acc;
    },
    {} as { [code: string]: Currency }
  );
