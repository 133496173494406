import {
  Button,
  ButtonProps,
  LinkBox,
  LinkOverlay,
  StyleProps,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface ButtonLinkProps extends StyleProps {
  to: string;
  children: React.ReactNode;
  buttonProps?: ButtonProps;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  to,
  children,
  buttonProps,
  ...styleProps
}) => {
  const { isDisabled } = buttonProps || {};

  return (
    <LinkBox {...styleProps}>
      <Button {...buttonProps}>
        {isDisabled ? (
          children
        ) : (
          <LinkOverlay as={Link} to={to}>
            {children}
          </LinkOverlay>
        )}
      </Button>
    </LinkBox>
  );
};
