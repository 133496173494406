import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Tab,
  TabList,
  Tabs,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useCurrentOrganizationUser } from "../../providers/CurrentOrganizationUserProvider";
import {
  eventNewUrl,
  homeUrl,
  organizationInfoUrl,
  organizationMembersUrl,
  organizationPassesUrl,
  organizationShowUrl,
  organizationWaiversUrl,
} from "../../services/routes/urlBuilder";
import { useCurrentOrganization } from "../../providers/CurrentOrganizationProvider";
import { OrganizationThemeCard } from "./components/OrganizationThemeCard";
import { ManageOrganizationMenuListItems } from "./components/ManageOrganizationMenuListItems";
import {
  deleteOrganization,
  inviteUserToOrganization,
} from "../../services/api/organizations";
import { ButtonLink } from "../../components/ButtonLink";
import { BiCalendarPlus, BiHome, BiShareAlt } from "react-icons/bi";
import { useOrganizationUsers } from "../../hooks/useOrganizationUsers";
import { useWaivers } from "../../hooks/useWaivers";
import { PageHeader } from "../../components/PageHeader";
import { InviteFriendsModal } from "../../components/InviteFriendsModal";
import { useMemo, useState } from "react";
import { JoinCommunityButton } from "./components/JoinCommunityButton";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import {
  hasPermission,
  PERMISSION_MANAGE_EVENTS,
  PERMISSION_MANAGE_ORGANIZATION,
} from "../../services/permissions";
import { PageBreadcrumb } from "../../components/PageBreadcrumb";
import { usePasses } from "../passes/hooks/usePasses";

export const OrganizationShowPage: React.FC = () => {
  const [isInviteFriendsModalOpen, setIsInviteFriendsModalOpen] =
    useState(false);
  const { organization } = useCurrentOrganization();
  const { currentUser } = useCurrentUser();
  const { passes } = usePasses(organization?.id);
  const { currentOrganizationUser, refetch: refetchCurrentOrganizationUser } =
    useCurrentOrganizationUser();
  const { users: organizationUsers } = useOrganizationUsers(organization?.id);
  const { waivers } = useWaivers(organization?.id);
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const onJoin = () => {
    refetchCurrentOrganizationUser();
  };

  const handleDelete = async () => {
    if (!organization) return;

    await deleteOrganization(organization.id);
    navigate(homeUrl());
  };

  const handleLeave = async () => {
    if (!currentOrganizationUser) return;
    await refetchCurrentOrganizationUser();
  };

  const handleInvite = async (userIds: string[]) => {
    if (!organization) return;
    await Promise.all(
      userIds.map((userId) => inviteUserToOrganization(organization.id, userId))
    );
  };

  const showDescription = !!organization?.description;
  const showMembers = currentOrganizationUser?.is_owner;
  const showPasses =
    passes.length > 0 ||
    currentOrganizationUser?.is_owner ||
    hasPermission(
      currentOrganizationUser?.permissions,
      PERMISSION_MANAGE_ORGANIZATION
    );
  const showWaivers =
    currentOrganizationUser?.is_owner ||
    (currentOrganizationUser && waivers && waivers.length > 0);

  const tabIndex = useMemo(() => {
    let numPreviousTabs = 1;
    if (pathname.includes("info")) {
      return numPreviousTabs;
    } else if (pathname.includes("members")) {
      if (showDescription) numPreviousTabs++;
      return numPreviousTabs;
    } else if (pathname.includes("passes")) {
      if (showDescription) numPreviousTabs++;
      if (showMembers) numPreviousTabs++;
      return numPreviousTabs;
    } else if (pathname.includes("waivers")) {
      if (showDescription) numPreviousTabs++;
      if (showMembers) numPreviousTabs++;
      if (showPasses) numPreviousTabs++;
      return numPreviousTabs;
    } else {
      return 0;
    }
  }, [pathname, showDescription, showMembers, showPasses]);

  if (!organization) return null;

  return (
    <>
      <InviteFriendsModal
        isOpen={isInviteFriendsModalOpen}
        inviteLink={`${process.env.REACT_APP_BASE_URL}${organizationShowUrl(
          organization.id
        )}`}
        onClose={() => setIsInviteFriendsModalOpen(false)}
        onInvite={handleInvite}
      />

      <PageHeader
        breadcrumbs={
          <PageBreadcrumb to={homeUrl()} label="Home" icon={<BiHome />} />
        }
        menuListContent={
          <ManageOrganizationMenuListItems
            organizationId={organization.id}
            onDelete={handleDelete}
            onLeave={handleLeave}
          />
        }
      />
      <OrganizationThemeCard
        theme={organization.theme}
        organizationName={organization.name}
        organizationId={organization.id}
        photoId={organization.photo_ids?.[0]}
      />

      <Flex gap={1} marginTop={1}>
        {currentOrganizationUser ? (
          <>
            <Button
              variant="primary"
              flexGrow={1}
              onClick={() => setIsInviteFriendsModalOpen(true)}
            >
              <Icon as={BiShareAlt} marginRight={2} />
              <Heading as="h3" size="sm">
                Invite a friend
              </Heading>
            </Button>
          </>
        ) : currentUser ? (
          <JoinCommunityButton
            organizationId={organization.id}
            onComplete={onJoin}
            flexGrow={1}
          />
        ) : null}
        {(currentOrganizationUser?.is_owner ||
          hasPermission(
            currentOrganizationUser?.permissions,
            PERMISSION_MANAGE_EVENTS
          )) && (
          <ButtonLink
            to={eventNewUrl({ organizationId: organization.id })}
            buttonProps={{ variant: "primary" }}
          >
            <HStack>
              <Icon as={BiCalendarPlus} />
              <Heading size="sm">Create event</Heading>
            </HStack>
          </ButtonLink>
        )}
      </Flex>

      <Tabs index={tabIndex}>
        <TabList marginTop={2}>
          <Link
            as={RouterLink}
            variant="unstyled"
            to={organizationShowUrl(organization.id)}
          >
            <Tab>Events</Tab>
          </Link>
          {showDescription && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={organizationInfoUrl(organization.id)}
            >
              <Tab>Info</Tab>
            </Link>
          )}
          {showMembers && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={organizationMembersUrl(organization.id)}
            >
              <Tab>{`${organizationUsers.length} ${
                organizationUsers.length === 1 ? "Member" : "Members"
              }`}</Tab>{" "}
            </Link>
          )}
          {showPasses && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={organizationPassesUrl(organization.id)}
            >
              <Tab>Memberships & passes</Tab>
            </Link>
          )}
          {showWaivers && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={organizationWaiversUrl(organization.id)}
            >
              <Tab>{`${
                waivers && waivers.length > 1
                  ? `${waivers.length} waivers`
                  : "Waiver"
              }`}</Tab>
            </Link>
          )}
        </TabList>
        <Outlet
          context={{
            showDescription,
            showMembers,
            showPasses,
            showWaivers,
          }}
        />
      </Tabs>
    </>
  );
};
