import { Heading, Text } from "@chakra-ui/react";
import { priceInDollars } from "../../services/payments";
import { CURRENCY_CODE_TO_CURRENCY } from "../../services/currencies";
import { Elements } from "@stripe/react-stripe-js";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { useStripeAccount } from "../../hooks/useStripeAccount";
import { Pass } from "../../types/passes";
import { PaymentForm } from "../events/components/PaymentForm";
import { useEffect, useState } from "react";
import { createPassPaymentIntent } from "../../services/api/payments";
import {
  organizationShowUrl,
  waiverSignAllUrl,
} from "../../services/routes/urlBuilder";
import { useSearchParams } from "react-router-dom";

interface PassOneTimePaymentProps {
  pass: Pass;
}

export const PassOneTimePayment: React.FC<PassOneTimePaymentProps> = ({
  pass,
}) => {
  const [paymentToken, setPaymentToken] = useState<string>();
  const { organization } = useOrganization(pass?.organization_id);
  const { stripe } = useStripeAccount(organization?.id);
  const [searchParams] = useSearchParams();

  const eventIdToEnroll = searchParams.get("eventIdToEnroll") || undefined;

  useEffect(() => {
    const init = async () => {
      const { paymentToken } = await createPassPaymentIntent(pass.id, {
        eventIdToEnroll,
      });
      setPaymentToken(paymentToken);
    };

    init();
  }, [pass, eventIdToEnroll]);

  if (!pass || !stripe || !paymentToken) return null;

  return (
    <>
      <Heading size="md" marginY={2}>
        Payment for {pass.name}
      </Heading>
      <Text marginY={4}>
        {priceInDollars("USD", pass.price_in_cents)}{" "}
        {CURRENCY_CODE_TO_CURRENCY["USD"].code}
      </Text>

      <Elements
        stripe={stripe}
        options={{
          clientSecret: paymentToken,
        }}
      >
        <PaymentForm
          returnUrl={`${process.env.REACT_APP_BASE_URL}${
            eventIdToEnroll
              ? waiverSignAllUrl(pass.organization_id, {
                  eventId: eventIdToEnroll,
                })
              : organizationShowUrl(pass.organization_id)
          }`}
        />
      </Elements>
    </>
  );
};
