import { Badge } from "@chakra-ui/react";
import { Organization } from "../../../types/organization";
import { ButtonLink } from "../../../components/ButtonLink";
import { adminManageOrganizationPayoutUrl } from "../../../services/routes/urlBuilder";

interface OrganizationSetupPaymentsBadgeProps {
  organization: Organization;
}

export const OrganizationSetupPaymentsBadge: React.FC<
  OrganizationSetupPaymentsBadgeProps
> = ({ organization }) => {
  if (!!organization.stripe_account_id) {
    return null;
  }

  return (
    <>
      <Badge colorScheme="red">Payments disabled</Badge>
      <ButtonLink
        to={adminManageOrganizationPayoutUrl(organization.id)}
        buttonProps={{
          variant: "ghost",
          size: "sm",
        }}
      >
        Setup payments
      </ButtonLink>
    </>
  );
};
