import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { VianikoUser } from "../types/users";
import { useAuth } from "./AuthProvider";
import * as api from "../services/api";

interface UserContext {
  currentUser: VianikoUser | undefined;
  initialized: boolean;
  refetch: () => void;
}

const CurrentUserContext = createContext<UserContext>({
  currentUser: undefined,
  initialized: false,
  refetch: () => {},
});

interface CurrentUserProviderProps {
  children: React.ReactNode;
}

export const CurrentUserProvider = ({ children }: CurrentUserProviderProps) => {
  const { user: authUser, initialized: authInitialized } = useAuth();
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<VianikoUser | undefined>(undefined);

  const fetchUser = useCallback(async () => {
    if (authUser) {
      const user = await api.users.getCurrentUser();
      setUser(user);
    } else {
      setUser(undefined);
    }
  }, [authUser]);

  useEffect(() => {
    const init = async () => {
      if (authInitialized) {
        await fetchUser();
        setInitialized(true);
      }
    };

    init();
  }, [fetchUser, authInitialized]);

  return (
    <CurrentUserContext.Provider
      value={{ currentUser: user, refetch: fetchUser, initialized }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = (): UserContext => {
  return useContext(CurrentUserContext);
};
