import { differenceInCalendarDays, differenceInMinutes } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { VianikoEvent } from "../../types/events";
import { RecurringEvent } from "../../types/recurring_events";
import { daysDifferenceBetweenDates, deltaTimeInMinutes } from "../timezone";
import ZonedDateTime from "../ZonedDateTime";

export const getDefaultTimeZone = (
  event: (VianikoEvent & RecurringEvent) | undefined
): string => {
  if (event?.iana_timezone) {
    return event.iana_timezone;
  } else {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
};

export const getDefaultEventStartDateTime = (
  event: (VianikoEvent & RecurringEvent) | undefined
): string => {
  if (event?.start_at) {
    return event.start_at;
  } else {
    const zdt = ZonedDateTime.now();
    zdt.addHours(1);
    zdt.roundToNearestHours();
    return zdt.toISOUtc();
  }
};

export const getDefaultEventEndDateTime = (
  event: (VianikoEvent & RecurringEvent) | undefined
): string => {
  if (event?.end_at) {
    return event.end_at;
  } else {
    const zdt = ZonedDateTime.now();
    zdt.addHours(2);
    zdt.roundToNearestHours();
    return zdt.toISOUtc();
  }
};

export const getEventDuration = (
  event: (VianikoEvent & RecurringEvent) | undefined
): number => {
  if (!event) return 60;
  return deltaTimeInMinutes(
    event!.start_at,
    event!.end_at,
    event.iana_timezone
  );
};

export const getEventCalendarDayCount = (
  event: (VianikoEvent & RecurringEvent) | undefined
): number => {
  if (!event) return 0;
  return daysDifferenceBetweenDates(
    event!.start_at,
    event!.end_at,
    event.iana_timezone
  );
};

export const getUpdatedEndAtWhenStartAtChanged = (
  newStartDateTime: string,
  endDateTime: string,
  timezone: string,
  eventDurationInMinutes: number,
  eventDurationInCalendarDays: number
): string => {
  // TODO: bug when start becomes 23 -> end 00 next day, then start is changed to 00 -> end should become 01 of the same day

  const newEventDurationInCalendarDays = daysDifferenceBetweenDates(
    newStartDateTime,
    endDateTime,
    timezone
  );

  const startDateIsAfterOrOnEndDate = newEventDurationInCalendarDays <= 0;

  if (startDateIsAfterOrOnEndDate || eventDurationInCalendarDays === 0) {
    const zdt = ZonedDateTime.fromUtc(newStartDateTime, timezone);
    zdt.addHours(eventDurationInMinutes / 60);
    return zdt.toISOUtc();
  }
  return endDateTime;
};

export const getUpdatedStartAtWhenEndAtChanged = (
  newEndDateTime: string,
  startDateTime: string,
  timezone: string,
  eventDurationInMinutes: number
): string => {
  const newEndDate = fromZonedTime(newEndDateTime, timezone);
  const startDate = fromZonedTime(startDateTime, timezone);
  const differenceInDays = differenceInCalendarDays(newEndDate, startDate);
  const endDateIsBeforeStartDate = differenceInDays < 0;
  const endDateIsBeforeOrOnStartDate = differenceInDays <= 0;
  const endTimeIsBeforeStartTime =
    differenceInMinutes(newEndDate, startDate) <= 0;

  const newStartDateZDT = ZonedDateTime.fromUtc(startDateTime, timezone);
  const newEndDateZDT = ZonedDateTime.fromUtc(newEndDateTime, timezone);

  if (endDateIsBeforeOrOnStartDate && endTimeIsBeforeStartTime) {
    newStartDateZDT.setTime(newEndDateZDT.toDisplayTime());
    newStartDateZDT.addHours(-Math.abs(eventDurationInMinutes) / 60);
  }

  if (endDateIsBeforeStartDate) {
    newStartDateZDT.setDate(newEndDateZDT.toDisplayDate());
  }
  return newStartDateZDT.toISOUtc();
};

export const getUpdatedEventDurationWhenEndAtChanged = (
  finalStartDate: string,
  finalEndDate: string,
  timezone: string,
  defaultEventDuration: number = 60
): number => {
  const endDate = fromZonedTime(finalEndDate, timezone);
  const startDate = fromZonedTime(finalStartDate, timezone);
  const differenceInDays = differenceInCalendarDays(endDate, startDate);
  const isSameDayEvent = differenceInDays === 0;

  if (isSameDayEvent) {
    return differenceInMinutes(endDate, startDate);
  } else {
    return defaultEventDuration;
  }
};

export const getUpdatedEventCalendarDays = (
  finalStartDate: string,
  finalEndDate: string,
  timezone: string
): number => {
  const endDate = fromZonedTime(finalEndDate, timezone);
  const startDate = fromZonedTime(finalStartDate, timezone);
  const differenceInDays = differenceInCalendarDays(endDate, startDate);
  return differenceInDays;
};
