import { Heading } from "@chakra-ui/react";
import { PassForm } from "./components/PassForm";
import { Pass } from "../../types/passes";
import { useNavigate, useParams } from "react-router-dom";
import { usePass } from "./hooks/usePass";
import { updatePass } from "../../services/api/passes";
import { useCurrentOrganization } from "../../providers/CurrentOrganizationProvider";
import { organizationPassesUrl } from "../../services/routes/urlBuilder";

export const EditPass: React.FC = () => {
  const { passId } = useParams();
  const { pass } = usePass(passId);
  const { organization } = useCurrentOrganization();
  const navigate = useNavigate();

  const handleSubmit = async (data: Pass) => {
    if (!organization) return;
    await updatePass(data);
    navigate(organizationPassesUrl(organization.id));
  };

  if (!pass) return null;

  return (
    <>
      <Heading size="lg">Edit pass</Heading>
      <PassForm onSubmit={handleSubmit} defaultValues={pass} />
    </>
  );
};
