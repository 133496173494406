import {
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
} from "@chakra-ui/react";
import { Panel } from "../../../../components/Panel";
import { Pass } from "../../../../types/passes";
import { UserPass } from "../../../../types/user_passes";
import { PassAboutText } from "../../../organizations/components/PassAboutText";

interface PassConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  pass: Pass;
  userPass: UserPass;
}

export const PassConfirmationModal: React.FC<PassConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  pass,
  userPass,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <HStack marginTop={2} marginBottom={4}>
            <Heading as="h3" size="lg">
              Use your pass
            </Heading>
            <Spacer />
            <ModalCloseButton marginTop={2} />
          </HStack>

          <Panel>
            <Heading size="md" as="h4">
              {pass.name}
            </Heading>

            <PassAboutText pass={pass} userPass={userPass} />
          </Panel>

          <Button
            onClick={onConfirm}
            variant="primary"
            size="lg"
            width="100%"
            marginY={4}
          >
            Use
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
