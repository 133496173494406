import { Box, Button, Heading, HStack, Spacer, Text } from "@chakra-ui/react";
import { priceInDollars } from "../../../services/payments";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { Pass } from "../../../types/passes";
import { ButtonLink } from "../../../components/ButtonLink";
import { passPaymentUrl } from "../../../services/routes/urlBuilder";
import { usePasses } from "../../passes/hooks/usePasses";
import { PassAboutText } from "./PassAboutText";
import { useMemo } from "react";

interface PassInfoProps {
  pass: Pass;
}

export const PassInfo: React.FC<PassInfoProps> = ({ pass }) => {
  const { currentUserPasses } = usePasses(pass.organization_id);

  const userPass = useMemo(() => {
    return currentUserPasses?.find(
      (user_pass) => user_pass.pass_id === pass.id
    );
  }, [currentUserPasses, pass.id]);

  return (
    <>
      <HStack align="start">
        <Box>
          <Heading as="h3" size="md">
            {pass.name}
          </Heading>

          <PassAboutText pass={pass} userPass={userPass} />

          {pass.description && (
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              {pass.description}
            </Text>
          )}
        </Box>

        <Spacer />

        <HStack>
          <Text size="md" color={TEXT_SECONDARY_COLOR}>
            {priceInDollars("USD", pass.price_in_cents)}
          </Text>

          {userPass?.is_valid ? (
            <Button isDisabled variant="primary" size="sm">
              Owned
            </Button>
          ) : (
            <ButtonLink
              to={passPaymentUrl(pass.id)}
              buttonProps={{
                variant: "primary",
                size: "sm",
              }}
            >
              Buy
            </ButtonLink>
          )}
        </HStack>
      </HStack>
    </>
  );
};
