import { Flex, Heading, VStack, Text, Image, Box } from "@chakra-ui/react";
import { ButtonLink } from "../../../components/ButtonLink";
import { authUrl, homeUrl } from "../../../services/routes/urlBuilder";

export type LandingPageBlockData = {
  title: string;
  description: string;
  buttonText?: string;
  image: string;
};

interface LandingPageSectionProps {
  title: string;
  description: string;
  buttonText?: string;
  image: string;
  textArrangement?: "left" | "right";
}

export const LandingPageSection: React.FC<LandingPageSectionProps> = ({
  title,
  description,
  buttonText,
  image,
  textArrangement,
}) => {
  const arrangement = textArrangement || "left";
  const isTextLeft = arrangement === "left";

  const hasSignInButton = buttonText !== undefined && buttonText !== "";

  return (
    <>
      <VStack spacing={0} align="left" mt="64px" mx="10">
        <Flex
          justify={{
            base: "center",
            md: "space-between",
          }}
          gap={8}
          wrap="wrap"
        >
          <Flex
            mt={{
              base: "0",
              lg: "20",
            }}
            alignItems="start"
            justify="left"
            direction="column"
            order={{
              base: 0,
              md: isTextLeft ? 0 : 1,
            }}
            // shrink={1}
            maxW={{
              base: "100%",
              md: "50%",
            }}
          >
            <Heading
              as="h1"
              size={{
                base: "xl",
                lg: "2xl",
              }}
            >
              {title}
            </Heading>
            <Text as="p" size="md" variant="secondary" mt="6">
              {description}
            </Text>
            {hasSignInButton && (
              <ButtonLink
                buttonProps={{ mt: 10, size: "lg", variant: "primary" }}
                to={authUrl(homeUrl())}
              >
                {buttonText}
              </ButtonLink>
            )}
          </Flex>
          <Box
            order={isTextLeft ? 1 : 0}
            maxW={{
              base: "100%",
              md: "45%",
            }}
          >
            <Image src={image} alt="Hero Image" fit="contain" />
          </Box>
          {/* <Image
            // flexShrink={0}
            src={image}
            alt="Hero Image"
            order={isTextLeft ? 1 : 0}
            fit="contain"
            maxW={{
              base: "100%",
              md: "45%",
            }}
          /> */}
        </Flex>
      </VStack>
    </>
  );
};
