import linkifyStr from "linkify-string";

export const getHtmlFromString = (string: string | undefined): string => {
  if (!string) {
    return "";
  }
  return linkifyStr(string).replace(/\n/g, "<br />");
};

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const r = result ? parseInt(result[1], 16) : 0;
  const g = result ? parseInt(result[2], 16) : 0;
  const b = result ? parseInt(result[3], 16) : 0;

  return { r, g, b };
}

export const hexToRgbWithOpacity = (hex: string, opacity: number) => {
  const rgb = hexToRgb(hex);

  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};

export const joinWithAnd = (arr: string[]) => {
  if (arr.length === 0) {
    return "";
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return arr.join(" and ");
  } else {
    const lastIndex = arr.length - 1;
    return arr.slice(0, lastIndex).join(", ") + " and " + arr[lastIndex];
  }
};

const dayStrings = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const mapWeekdaysToString = (days: number[]) => {
  return joinWithAnd(days.map((day) => `${dayStrings[day]}s`));
};
