import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchUnsignedWaivers } from "../../services/api/payments";
import {
  eventShowUrl,
  organizationShowUrl,
  waiverShowUrl,
  waiverSignAllUrl,
} from "../../services/routes/urlBuilder";

export const SignAllWaivers: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");

  useEffect(() => {
    const init = async () => {
      if (!organizationId) return;

      const [firstWaiver] = await fetchUnsignedWaivers(organizationId);

      if (firstWaiver) {
        navigate(
          waiverShowUrl(
            organizationId,
            firstWaiver.id,
            waiverSignAllUrl(organizationId, { eventId: eventId ?? undefined })
          )
        );
      } else if (eventId) {
        navigate(
          eventShowUrl(eventId, {
            welcome: true,
            invite: true,
          })
        );
      } else {
        navigate(organizationShowUrl(organizationId));
      }
    };

    init();
  }, [eventId, navigate, organizationId]);

  return null;
};
