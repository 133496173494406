import React, { useState } from "react";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useStripeConnect } from "../../../hooks/useStripeConnect";
import { createAccount } from "../../../services/api/payments";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useStripeAccount } from "../../../hooks/useStripeAccount";
import { useNavigate } from "react-router-dom";
import { organizationShowUrl } from "../../../services/routes/urlBuilder";
import { Panel } from "../../../components/Panel";

export const PaymentAccount = () => {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const { organization, refetch: refetchOrg } = useCurrentOrganization();
  const { stripeConnectInstance, refetch: refetchConnect } = useStripeConnect(
    organization?.id
  );
  const { stripeAccountId, refetch: refetchStripeAccount } = useStripeAccount(
    organization?.id
  );
  const navigate = useNavigate();

  const handleCreateAccount = async () => {
    if (!organization) return;

    setAccountCreatePending(true);
    setError(false);

    const { error } = await createAccount(organization.id);
    if (error) {
      setError(true);
    }

    await Promise.all([refetchStripeAccount(), refetchOrg(), refetchConnect()]);

    setAccountCreatePending(false);
  };

  const handleComplete = () => {
    setCompleted(true);

    setTimeout(() => {
      console.log("org timeout", organization);
      if (organization) {
        navigate(organizationShowUrl(organization.id));
      }
    }, 2000);
  };

  return (
    <Panel>
      <Flex align="center" direction="column">
        {stripeConnectInstance && stripeAccountId ? (
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding onExit={handleComplete} />
          </ConnectComponentsProvider>
        ) : accountCreatePending ? (
          <Spinner />
        ) : (
          <>
            <Text>Setup your payout account</Text>
            <Button variant="outline" onClick={handleCreateAccount}>
              Begin setup
            </Button>
          </>
        )}
        {error && <Text>Something went wrong!</Text>}
        {accountCreatePending && <Text>Creating a connected account...</Text>}
        {completed && <Spinner />}
      </Flex>
    </Panel>
  );
};
