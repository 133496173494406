import heic2any from "heic2any";

export const getImageUrl = (photoId: string): string => {
  return `https://storage.googleapis.com/${process.env.REACT_APP_IMAGES_STORAGE_BUCKET}/photos/${photoId}/image.png`;
};

export const getStaticImageUrl = (gcpImagePath: string): string => {
  let imgPath = gcpImagePath;
  if (!gcpImagePath.startsWith("/")) {
    imgPath = `/${gcpImagePath}`;
  }
  return `https://storage.googleapis.com/${process.env.REACT_APP_IMAGES_STORAGE_BUCKET}${imgPath}`;
};

export const converHeicToPng = async (file: File): Promise<File> => {
  const arrayBuffer = await file.arrayBuffer();
  const blob = new Blob([arrayBuffer]);

  const heicBlob = await heic2any({ blob, toType: "image/png" });
  const pngFile = new File(
    [heicBlob as Blob],
    file.name.replace(/\.heic$/i, ".png"),
    {
      type: "image/png",
    }
  );

  return pngFile;
};
