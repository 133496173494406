import { useState } from "react";
import {
  Box,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { BiDotsHorizontalRounded, BiPencil, BiTrash } from "react-icons/bi";
import { Pass } from "../../../types/passes";
import { passEditUrl } from "../../../services/routes/urlBuilder";
import { deletePass } from "../../../services/api/passes";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";

interface ManagePassInfoMenuProps {
  pass: Pass;
  onDelete: () => void;
}

export const ManagePassInfoMenu: React.FC<ManagePassInfoMenuProps> = ({
  pass,
  onDelete,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDelete = async () => {
    await deletePass(pass.id);
    onDelete();
  };

  return (
    <>
      <Box position="relative">
        <IconButton
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          variant="ghost"
          aria-label="More"
          icon={<Icon as={BiDotsHorizontalRounded} />}
          margin={0}
        />
        <Menu
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          closeOnBlur
        >
          <MenuList>
            <Link
              as={RouterLink}
              to={passEditUrl(pass.organization_id, pass.id)}
            >
              <MenuItem>
                <Icon as={BiPencil} marginRight={2} />

                <Text size="md">Edit pass</Text>
              </MenuItem>
            </Link>

            <MenuItem
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              <Icon as={BiTrash} marginRight={2} />

              <Text size="md">Delete pass</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
};
