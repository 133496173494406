import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { MyOrganizationCalendar } from "./components/MyOrganizationCalendar";

export const MyOrganization: React.FC = () => {
  return (
    <>
      <Heading as="h1" size="lg" marginY={4}>
        My organization
      </Heading>
      <Tabs>
        <TabList>
          <Tab>Events</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MyOrganizationCalendar />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
